import styled from 'styled-components'
import { ImageAsset, TextLegacy } from '@/components/common'
import { NewButtonToHome } from '@/components/common/buttons/new/NewButtonToHome'
import { LayoutHeader } from '@/components/domains/layouts'
import { SCREEN_MEDIA_QUERY } from '@/constants/legacy/constLayout'

const Page404Error = () => {
  return (
    <>
      <LayoutHeader />
      <StyledContainer>
        <ImageAsset name="ic_404" size={80} />
        <StyledTextContainer>
          <TextLegacy size={{ xs: 14, md: 16 }}>현재 페이지를 표시할 수 없습니다.</TextLegacy>
        </StyledTextContainer>
        <NewButtonToHome />
      </StyledContainer>
    </>
  )
}

const StyledContainer = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${SCREEN_MEDIA_QUERY.xs} {
    height: 80vh;
  }
`

const StyledTextContainer = styled.div`
  margin: 1.6rem 0;
`

export default Page404Error
