import styled, { useTheme } from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { IconNameEnum } from '@/constants/icon-name.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { useCustomRouter } from '@/containers/hooks'
import { Bridge } from '@/utils/bridge/bridge'
import appBridgeProvider, { isInAppFlag } from '@/utils/utilBridge'

export const NewButtonToHome = () => {
  const { push } = useCustomRouter()
  const { color } = useTheme()
  const handleClickToHome = () => {
    if (isInAppFlag) {
      appBridgeProvider((bridge: Bridge) => bridge.goDeepLink('fitpetmall', '/main'))
    } else {
      push(ROUTES.INDEX)
    }
  }
  return (
    <StyledButton type="button" onClick={handleClickToHome}>
      <Typo variant={TypoVariant.Body4Regular}>홈으로 가기</Typo>
      <FlatIcon type={IconNameEnum.IcChevronRight} size="1.6rem" color={color.gray900} />
    </StyledButton>
  )
}

const StyledButton = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.color.gray200};
  padding: 1.2rem 4rem;
  background: ${({ theme }) => theme.color.grayWhite};
  border-radius: 0.8rem;
`
